import React, {useState} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import { setUser } from './userSlice';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAuthStatus } from '../../request/Auth/authSlice';

const UpdatePassword = () => {
    const dispatch = useDispatch();
    const initialLogin = {
        code: '',
        email: '',
        password: '',
        password_confirmation: '',

    }
   
    const [error, setErros] = useState('');
    const [registerForm, setRegisterForm] = useState(initialLogin);
    const appMode = useSelector((state) => state.mode.mode);
    const redirectTo = useSelector((state) => state.redirect.redirect);
    const navigate = useNavigate();

    const onChange = (e) =>
    setRegisterForm({ ...registerForm, [e.target.name]: e.target.value });

    const { isLoading: isSendingRequest, mutate: postLogin } = useMutation(

        async () => {
          return await apiClient.post(`/api/update-password`, {
            code: registerForm.code,
            email: registerForm.email,
            password: registerForm.password,
            password_confirmation: registerForm.password_confirmation,

          });
        },
        {
          onSuccess: (res) => {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);

            navigate('/login');

          },
          onError: (err) => {   
            let myerror = err.response?.data || err;         
            setErros(myerror.errors)  
            const event2 = new Event('processed');
            window.dispatchEvent(event2);     
          },
        }
      );
      const onSubmit = async() =>{
        const event3 = new Event('processing');
        window.dispatchEvent(event3);
        postLogin();
        // await apiClient.get("/sanctum/csrf-cookie").then(() => {
          
        // })
      } 

  return (
    <div style={{marginTop: '70px'}}>
        <Grid container px={2} mt={4}  mb={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={12} md={8} sx={{display: {xs: 'none', md: 'flex'}}}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardMedia
                        component="img"
                        sx={{ width: '100%', height: "100%" }}
                        image='https://res.cloudinary.com/dzxyvdq14/image/upload/v1691381238/bgsearis-1.jpg'
                        alt='login'
                    />
                </Card>
            </Grid>
            <Grid item xs={12} md={4}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <img  src={appMode === 'dark'? 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1696600776/movialinvestment-logo.png' : 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1696600776/movialinvestment-logo.png'} style={{width: '154px', height: '63px', marginBottom: '20px'}} alt="logo" />
                    <Divider />
                    <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                        Update Password
                    </Typography>
                    <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        Enter the verification code sent to your email then fill other fields to update your password
                    </Typography>

                    <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="code">Verification Code</InputLabel>
                            {(error !== '' && error.code) ?
                            <OutlinedInput
                            error
                            onChange={onChange}
                            id="code"
                            name="code"
                            type={'text'}
                            label="Verification Code"
                            helperText={error.code}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="code"
                            name="code"
                            type={'text'}
                            label="Verification Code"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.code}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="email">Email</InputLabel>
                            {(error !== '' && error.email) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="email"
                            type={'email'}
                            name="email"
                            label="Email"
                            helperText={error.email}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="email"
                            type={'email'}
                            name="email"
                            label="Email"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.email}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="password">New Password</InputLabel>
                            <OutlinedInput
                            onChange={onChange}
                            id="password"
                            name="password"
                            type={'password'}
                            label="New Password"
                            />
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="password_confirmation">Confirm Password</InputLabel>
                            {(error !== '' && error.password) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            id="password_confirmation"
                            name="password_confirmation"
                            type={'password'}
                            label="Confirm Password"
                            helperText={error.password}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="password_confirmation"
                            name="password_confirmation"
                            type={'password'}
                            label="Confirm Password"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.password}
                            </Typography>
                        </FormControl>

                    </CardContent>                    
                    <CardActions>
                        <Button onClick={onSubmit} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'updating...' : 'Update Password'}</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    </div>
  )
}

export default UpdatePassword
